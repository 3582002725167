<template>
  <v-container>
    <v-row
      class="mb-3"
      justify="center"
    >
      <v-col justify="center">
        <v-icon
          color="primary"
          dense
          @click="goBackCampaign"
        >
          {{ icons.mdiChevronLeft }}
        </v-icon>
        <span
          class="font-weight-black ml-1 "
          style="font-size: 20px;"
        >
          {{ 'Need Approval' }}
        </span>
      </v-col>
    </v-row>
    <v-row
      v-if="selected.length"
      class="mb-5 mt-0"
    >
      <v-col>
        <v-btn
          large
          outlined
          class="me-3"
          color="primary"
          @click="approvePost()"
        >
          Approve
        </v-btn>
        <v-btn
          large
          outlined
          color="error"
          @click.stop="showRejectModal = true"
        >
          Reject
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-if="showAlert == true"
      v-model="showAlert"
      max-width="400"
    >
      <v-card>
        <v-alert
          dense
          text
          type="success"
        >
          Post successfully <strong>Approved</strong>
        </v-alert>
        <v-row
          justify="end"
          class="mb-2 mr-1"
        >
          <v-btn
            color="green darken-1"
            text
            @click="showAlert = false"
          >
            Close
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="postNeedApprove"
      :single-select="singleSelect"
      show-select
      class="elevation-1"
    >
      <template v-slot:item.id="{ item }">
        <v-row>
          <v-dialog
            v-model="dialog"
            scrollable
            max-width="600px"
            :retain-focus="false"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                small
                outlined
                color="primary"
                v-on="on"
                @click="goToPreview(item)"
              >
                Preview Post
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Preview Post</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 600px;">
                <!-- Start tab -->
                <v-row
                  justify="space-between"
                  align-content="center"
                  align="center"
                >
                  <v-col
                    cols="12"
                    sm="auto"
                  >
                    <v-tabs>
                      <v-tab
                
                        class="text-capitalize"
                        @click="previewPost = 'WhatsApp'"
                      >
                        WhatsApp
                      </v-tab>
                      <v-tab
                
                        class="text-capitalize"
                        @click="previewPost = 'Facebook'"
                      >
                        Facebook
                      </v-tab>
                      <v-tab
                
                        class="text-capitalize"
                        @click="previewPost = 'Instagram'"
                      >
                        Instagram
                      </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <!-- End tab -->
                <PreviewPostWhatsapp 
                  v-if="previewPost === 'WhatsApp'"
                  v-model="postSelected"
                />
                <PreviewPostInstagram
                  v-if="previewPost === 'Instagram'"
                  v-model="postSelected"
                  class="mb-4"
                />
                <PreviewPostFacebook
                  v-if="previewPost === 'Facebook'"
                  v-model="postSelected"
                />
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-row
                  justify="end"
                  class="py-2"
                >
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      small
                      outlined
                      class="me-3"
                      :style="{ 'background-color': hover ? '#1F979E' : '#fff', color: hover ? '#fff' : '#1F979E' }"
                      @click="approvePost(item)"
                    >
                      Approve
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      small
                      outlined
                      :style="{ 'background-color': hover ? '#FF4C51' : '#fff', color: hover ? '#fff' : '#FF4C51' }"
                      @click.stop="showRejectModal = true"
                    >
                      Reject
                    </v-btn>
                  </v-hover>
                  <reject-confirmation-dialog
                    v-model="showRejectModal"
                    :item="item"
                    :post-need-approve="postNeedApprove"
                    :get-post-templates="getPostTemplates"
                    :posts="selected"
                  />
                  <v-btn
                    color="primary"
                    small
                    text
                    outlined
                    class="ml-2"
                    @click.stop="dialog = false"
                  >
                    Close
                  </v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import useAppConfig from '@/@core/@app-config/useAppConfig'
import { mdiChevronLeft } from '@mdi/js'
import RejectConfirmationDialog from './RejectConfirmationDialog.vue'
import PreviewPostFacebook from './components/approval/preview/PreviewPostFacebook.vue'
import PreviewPostWhatsapp from './components/approval/preview/PreviewPostWhatsapp.vue'
import PreviewPostInstagram from './components/approval/preview/PreviewPostInstagram.vue'

export default {
  setup() {
    const { isDark } = useAppConfig()

    return {
      icons: { mdiChevronLeft },
      isDark,
    }
  },
  components: { RejectConfirmationDialog, PreviewPostFacebook, PreviewPostWhatsapp, PreviewPostInstagram },
  data() {
    return {
      singleSelect: false,
      selected: [],
      showRejectModal: false,
      headers: [
        { text: 'Name post', value: 'post_name' },
        { text: 'Preview Post', value: 'id' },
        { text: 'Status', value: 'statusApproval' },
        { text: 'Post Creator', value: 'post_creator.name' },
      ],
      campaignData: [
        { post_name: 'Name post one', statusApproval: 'Need approval' },
        { post_name: 'Name post two', statusApproval: 'Need approval' },
        { post_name: 'Name post three', statusApproval: 'Need approval' },
        { post_name: 'Name post four', statusApproval: 'Need approval' },
        { post_name: 'Name post five', statusApproval: 'Need approval' },
        { post_name: 'Name post six', statusApproval: 'Need approval' },
        { post_name: 'Name post seven', statusApproval: 'Need approval' },
        { post_name: 'Name post eight', statusApproval: 'Need approval' },
        { post_name: 'Name post nine', statusApproval: 'Need approval' },
      ],
      postApproval: [],
      showAlert: false,
      dialog: false,
      postSelected: {
        channels: [],
      },
      previewPost: 'WhatsApp',
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    allCampaigns() {
      return this.$store.getters['campaign_manager/getAllCampaignsData']
    },
    postNeedApprove() {
      const campaignByApprover = this.allCampaigns.filter(el => {
        return el.approvers.includes(this.user.role || this.user.email)
      })
      const postByCampaignRole = []
      this.postApproval.forEach(post => {
        campaignByApprover.forEach(campaign => {
          if (
            campaign.id === post.idCampaign &&
            post.statusApproval === 'Need Approval' &&
            post.approvement.length < 1
          ) {
            const postFormatted = post
            postFormatted.channels = campaign.channelType
            postByCampaignRole.push(postFormatted)
          } else if (
            campaign.id === post.idCampaign &&
            post.statusApproval === 'Need Approval' &&
            post.approvement.length > 0 &&
            post.approvement.find(el => {
              return el.user.email !== this.user.email
            })
          ) {
            const postFormatted = post
            postFormatted.channels = campaign.channelType
            postByCampaignRole.push(postFormatted)
          }
        })
      })

      return postByCampaignRole
    },
  },
  mounted() {
    this.getPostTemplates()
  },
  methods: {
    goBackCampaign() {
      this.$router.back()
    },
    async approvePost(item) {
      if (this.selected.length) {
        const resultPost = []
        this.allCampaigns.forEach(camp => {
          this.selected.forEach(selectedItem => {
            if (camp.id === selectedItem.idCampaign) {
              const temp = {
                campaign: camp,
                post: {
                  ...selectedItem,
                  approvement: {
                    user: this.user,
                    statusApproval: 'Approved',
                  },
                },
                totalApprover: camp.approvers.length,
              }
              resultPost.push(temp)
            }
          })
        })

        const result = await this.$store.dispatch('marketingCalendar/updateManyStatus', {
          sub_id: this.user.sub_id,
          data: {
            posts: resultPost,
          },
          token: this.user.token,
        })
        if (result.status) {
          this.showAlert = true
          this.getPostTemplates()
          this.dialog = false
        }
      } else {
        let campaign
        this.allCampaigns.forEach(camp => {
          if (camp.id === item.idCampaign) {
            campaign = camp
          }
        })
        const totalApprover = campaign.approvers.length
        const approvement = {
          user: this.user,
          statusApproval: 'Approved',
        }
        const result = await this.$store.dispatch('marketingCalendar/updateStatus', {
          sub_id: this.user.sub_id,
          approvement,
          id: Number(item.id),
          totalApprover,
          token: this.user.token,
        })
        if (result.status) {
          this.showAlert = true
          this.getPostTemplates()
        }
      }
    },
    async getPostTemplates() {
      // to fetch all Post
      await this.$store.dispatch('marketingCalendar/fetchAllPostTemplates', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })
      this.postApproval = this.$store.getters['marketingCalendar/getPostApproval']
    },
    goToPreview(item) {
      this.dialog = true
      this.postSelected = item
      //console.log(this.postSelected, 'this.postSelected')
    },
  },
}
</script>
<style scoped>
.v-btn {
  background-color: 'red';
  color: '#fff';
}
</style>
